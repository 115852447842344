<template>
  <div class="preloader" v-if="$store.state.spinner">
      <div class="loader">
        <div class="spinner">
          <div class="spinner-container">
            <div class="spinner-rotator">
              <div class="spinner-left">
                <div class="spinner-circle"></div>
              </div>
              <div class="spinner-right">
                <div class="spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Spinner',
  methods: {
    show(){
      this.$store.commit('showSpinner');
    },
    hide(){
      this.$store.commit('hideSpinner');
    }
  },
  data() {
    return {
        
    }
  },
  created() {
    this.show();
    setTimeout(() => this.hide(), 500);
  }
}
</script>
<style scoped>
.preloader {
  background-color: rgba(0,0,0,0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10500;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 8px solid #fff; /* Light grey */
  border-top: 8px solid #000; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
