<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5 text-center text-white">
          <img class="img-fluid img-footer" src="/img/logo.png">
          <br>
          <p>
            &copy; 2024 Saturno Consulting Srl<br>
            Via Cascina 6, 67100 L'Aquila AQ<br>
            P.IVA 02009750668<br>
            Codice SDI: M5UXCR1<br>
            PEC: saturnoconsulting@kelipec.it
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer'
}
</script>

<style scoped>
footer{
  background-color: #000;
  width: 100%;
  overflow: hidden;
}

.img-footer{
  margin-bottom: 30px;
  width: 150px;
}
</style>
