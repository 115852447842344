<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left">Divisione di consulenza industriale</h1>
          <p class="text-paragraph">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.
          </p>
        </div>
        <div class="col-lg-7 mb-3">
          <BoxParticles :height="400"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Come lo facciamo</h4>
          <TextScroller :class="'home1'" :text="'However, for brands, AI often feels distant and confined to expert developers, especially in eCommerce where reliance on off-the-shelf SAAS products can lead to outdated technology and a significant gap between innovation and accessibility. We have closed that gap.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="cardblock border" style="height: 400px;">
            <div class="body">
              <h1 class="mb-5" data-aos="fade-down">Dedizione ed esperienza</h1>
              <p class="text-paragraph">
                Con la sua insuperabile formazione, l’ing. Priori ha deciso di utilizzare la propria professionalità e il suo nome a servizio
                della consulenza aziendale.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock border" style="height: 400px;">
            <div class="body">
              <h1 class="mb-5" data-aos="fade-down">Esperienza lodevole</h1>
              <p class="text-paragraph">
                Grazie ad un trentennale trascorso nel settore dell’industria chimica della gomma e dopo la lunga esperienza in diverse multinazionali
                (Bridgestone, Manuli, Trelleborg, Marangoni)
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock border" style="height: 400px;">
            <div class="body">
              <h1 class="mb-5" data-aos="fade-down">Campi di applicazione</h1>
              <p class="text-paragraph">
                Le sue expertise, riconosciute nel settore a livello mondiale, spiccano nel campo della formulazione delle mescole
                e della progettazione ed efficientamento di stabilimenti greenfield o brownfield.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Industrial",
  components: {TextScroller, BoxParticles},
  mounted(){

  },
  methods : {

  }
}
</script>

<style scoped>
.border{
  border: 1px solid #dedede;
}
</style>