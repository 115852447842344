<template>
  <a href="#" class="scroll-top" v-on:click="scrollToTop" v-if="scrolled">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
    </svg>
  </a>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ScrollTop',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style scoped>

.scroll-top {
  width: 50px;
  height: 50px;
  background-color: transparent;
  position: fixed;
  bottom: 16px;
  right: 20px;
  z-index: 9999;
}

.scroll-top:hover {
  cursor: pointer;
}

.scroll-top i {
  font-size: 20px;
}

.scroll-top svg{
  fill: #7a7a7a;
}

</style>
