<template>
  <div :class="'animation ' + this.class">
    <h1>
      <template v-for="(word,index) in words" v-bind:key="index">
        <div>{{word}}<span>{{ word }}</span></div>
      </template>
    </h1>
  </div>
</template>
<script>
/* eslint-disable */
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {charAt} from "core-js/internals/string-multibyte";
export default {
  name: "TextScroller",
  props: {
    text: {
      type: String,
      required: true
    },
    class : {
      type : String,
      required: true
    }
  },
  watch : {
    //se cambia la lingua devo aggiornare la prop text
    "text": function(oldVal, newVal) {
      this.words = this.text.split(" ")
    }
  },
  data () {
    return {
      words : this.text.split(" ")
    }
  },
  mounted() {
    this.scrollAnimation();
  },
  methods : {
    charAt,
    scrollAnimation(){
      gsap.set('.animation span', { width: 0 });
      gsap.registerPlugin(ScrollTrigger);
      gsap.timeline({
        scrollTrigger:{
          trigger: '.' + this.class,
          scrub:0.3,
          start:"top 90%",
          end:"top 20%",
          //markers:true
        }
      }).to('.' + this.class + ' span', {
        width:'100%',
        duration:1, ease:'none',
        stagger:1
      });
      /*
      const animboxes = gsap.utils.toArray('.animation');
      animboxes.forEach(box => {
        gsap.timeline({
          scrollTrigger:{
            trigger: '.' + this.class,
            scrub:0.3,
            start:"bottom 95%",
            end:"top 20%",
            markers:true
          }
        }).to('.' + this.class + ' span', {
          width:'100%',
          duration:1, ease:'none',
          stagger:1
        });
      });
       */
    }
  }
}
</script>
<style scoped>
.animation{
  width: 100%;
  overflow: hidden;
}

.animation div{
  position: relative;
  font-family: sans-serif;
  text-align: center;
}

.animation div {
  margin-right: 10px;
  display: inline-block;
}

.animation span{
  position: absolute;
  width: 0;
  height: 100%;
  color: #fff;
  left: 0;
  overflow:hidden;
  text-align: center;
}
</style>