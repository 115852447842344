<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="title-section" data-aos="fade-left">WOO<br>CONNECTOR</h1>
          <p class="text-paragraph">
            Perché continuare a fare il doppio lavoro?<br>
            Con il nostro <b>plug-in per Woocommerce</b> avrai finalmente sincronizzati tutti i prodotti e i movimenti tra l’attività
            online e quella in negozio in tempo reale sul software <b>Cassa in Cloud</b>. Contattaci per installarlo sul tuo sito web dal bottone qui sotto.
          </p>
          <router-link class="btn btn-outline-dark btn-lg" to="/contatti">Contattaci</router-link>
        </div>
        <div class="col-lg-6 mt-4">
          <img class="img-fluid" src="/img/connettore/wc1.png">
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Vuoi dare una svolta decisiva al tuo business?</h4>
          <TextScroller :class="'casa1'" :text="'Il nostro plugin ti permette di vendere lo stesso prodotto sia in negozio con Cassa In Cloud che online con il tuo sito di e-commerce con un unico inserimento dati e con un solo magazzino, tutto sincronizzato. Il WooConnector importerà in WooCommerce l’intera scheda prodotto con tutte le informazioni necessarie (prezzi, quantità, tag, varianti, taglie, descrizioni e foto). La sincronizzazione immediata eliminerà il rischio di vendere lo stesso prodotto in negozio quando è appena stato venduto online.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Le funzionalità</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m3.514 6.61c-.317.179-.514.519-.514.887v8.95c0 .37.197.708.514.887 1.597.901 6.456 3.639 8.005 4.512.152.085.319.128.487.128.164 0 .328-.041.477-.123 1.549-.855 6.39-3.523 7.994-4.408.323-.177.523-.519.523-.891v-9.055c0-.368-.197-.708-.515-.887-1.595-.899-6.444-3.632-7.999-4.508-.151-.085-.319-.128-.486-.128-.168 0-.335.043-.486.128-1.555.876-6.405 3.609-8 4.508m15.986 2.115v7.525l-6.75 3.722v-7.578zm-15 7.425v-7.458l6.75 3.75v7.511zm.736-8.769 6.764-3.813 6.801 3.834-6.801 3.716z" fill-rule="nonzero"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Scorte aggiornate tra sito e gestionale</h2>
          <p class="text-paragraph">
            Le giacenze di magazzino verranno aggiornate automaticamente tra il tuo sito web e-commerce e il software
            gestionale di cassa Cassa in Cloud in modo da ottenere una gestione centralizzata delle giacenze su tutti i canali di vendita.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.6 11c0-.552-.448-1-1-1-1.655 0-4.945 0-6.6 0-.552 0-1 .448-1 1v9c0 .552.448 1 1 1h6.6c.552 0 1-.448 1-1 0-2.092 0-6.908 0-9zm9.4 6c0-.552-.448-1-1-1h-6c-.538 0-1 .477-1 1v3c0 .552.448 1 1 1h6c.552 0 1-.448 1-1zm-1.5.5v2h-5v-2zm-9.4-6v8h-5.6v-8zm10.9-7.5c0-.552-.448-1-1-1-1.537 0-4.463 0-6 0-.552 0-1 .448-1 1v9.6c0 .552.448 1 1 1h6c.552 0 1-.448 1-1 0-2.194 0-7.406 0-9.6zm-1.5.5v8.6h-5v-8.6zm-7.9-.5c0-.552-.448-1-1-1-1.655 0-4.945 0-6.6 0-.552 0-1 .448-1 1v3.6c0 .552.448 1 1 1h6.6c.552 0 1-.448 1-1 0-1.017 0-2.583 0-3.6zm-1.5.5v2.6h-5.6v-2.6z" fill-rule="nonzero"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Gestione da un solo pannello di controllo</h2>
          <p class="text-paragraph">
            Potrai centralizzare tutte le operazioni di sincronizzazione del tuo negozio online e del punto vendita fisico da un
            unico pannello di controllo su Wordpress, semplificando il processo di aggiornamento delle informazioni e riducendo
            il rischio di errori.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.919 17.377l-4.869-13.377h-2.05c-.266 0-.52-.105-.707-.293-.188-.187-.293-.442-.293-.707 0-.552.447-1 1-1h3.45l5.469 15.025c.841.101 1.59.5 2.139 1.088l11.258-4.097.684 1.879-11.049 4.021c.032.19.049.385.049.584 0 1.932-1.569 3.5-3.5 3.5-1.932 0-3.5-1.568-3.5-3.5 0-1.363.781-2.545 1.919-3.123zm1.581 1.811c.724 0 1.312.588 1.312 1.312 0 .724-.588 1.313-1.312 1.313-.725 0-1.313-.589-1.313-1.313s.588-1.312 1.313-1.312zm13.314-6.512l-11.418 4.156-2.736-7.518 11.418-4.156 2.736 7.518zm-8.71-3.215l-2.88 1.048 1.368 3.759 7.659-2.787-1.368-3.759-2.9 1.055.684 1.879-1.879.685-.684-1.88zm2.672-4.165l-8.458 3.078-1.927-5.296 8.457-3.078 1.928 5.296zm-3.123-2.733l-4.699 1.711.56 1.537 4.698-1.71-.559-1.538z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Magazzino unico per online e offline</h2>
          <p class="text-paragraph">
            Con questa soluzione, puoi mantenere un controllo centralizzato delle giacenze
            di magazzino, evitando discrepanze e garantendo un aggiornamento automatico delle quantità disponibili
            su entrambi i canali di vendita.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 7h-4v-3c0-2.209-1.791-4-4-4s-4 1.791-4 4v3h-4l-2 17h20l-2-17zm-11-3c0-1.654 1.346-3 3-3s3 1.346 3 3v3h-6v-3zm-4.751 18l1.529-13h2.222v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h6v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h2.222l1.529 13h-15.502z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Gestione e-commerce semplificata</h2>
          <p class="text-paragraph">
            Con la nostra gestione e-commerce semplificata, concentrati sulla crescita della tua attività senza le
            complicazioni della gestione manuale.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 18h-2c-.552 0-1-.448-1-1v-13c0-.552.448-1 1-1h13c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.089-.743 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-3-2.8h-13v2h1.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h7.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6zm-2-9h-11v7h11v-7zm3 4v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Gestione delivery direttamente sul punto cassa</h2>
          <p class="text-paragraph">
            Il nostro plugin ora permette anche la gestione e la sincronizzazione degli ordini dal sito web al software di cassa.
            Ritroverai gli ordini delivery effettuati online sul tuo sito e-commerce anche sul portale delivery di Cassa in Cloud.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextScroller from "@/components/TextScroller.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Connettore",
  components: {
    TextScroller

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>