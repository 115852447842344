<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">OPS!...<br>PAGE NOT FOUND</h1>
          <p class="text-paragraph">
            The page are you looking for is deleted or moved.
          </p>
          <router-link to="/" class="btn btn-dark btn-lg">Go to home</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "NotFound",
  mounted() {

  }
}
</script>

<style scoped>

</style>