<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section mb-5" data-aos="fade-left">{{$t('casestudies.lead')}}</h1>
          <p class="text-paragraph mb-5" v-html="$t('casestudies.textlead')"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3">
          <router-link to="/bikinilovers">
            <img class="img-fluid border-brand" src="/img/bkl2.png">
          </router-link>
        </div>
        <div class="col-lg-4 mb-3">
          <router-link to="/lamoraglamour">
            <img class="img-fluid border-brand" src="/img/lamora2.png">
          </router-link>
        </div>
        <div class="col-lg-4 mb-3">
          <router-link to="/vinoecioccolato">
            <img class="img-fluid border-brand" src="/img/vec.png">
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>