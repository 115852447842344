<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left" v-html="$t('lamoraglamour.lead')"></h1>
          <p class="text-paragraph" v-html="$t('lamoraglamour.textlead')"></p>
        </div>
        <div class="col-lg-6 mt-4 mb-3">
          <a target="_blank" href="https://www.lamoraglamour.com/">
            <img class="img-fluid border-brand" src="/img/lamora2.png">
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4" v-html="$t('lamoraglamour.need')"></h4>
          <TextScroller :class="'digital1'" :text="$t('lamoraglamour.textneed')"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cardblock">
            <h1 class="mb-5 title-section" data-aos="fade-down" v-html="$t('lamoraglamour.target')"></h1>
            <p class="text-paragraph" v-html="$t('lamoraglamour.texttarget')"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left" v-html="$t('lamoraglamour.solutionslead')"></h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left" v-html="$t('lamoraglamour.titlesol1')"></h2>
          <p class="text-paragraph" v-html="$t('lamoraglamour.textsol1')"></p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+123% Data</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left" v-html="$t('lamoraglamour.titlesol2')"></h2>
          <p class="text-paragraph" v-html="$t('lamoraglamour.textsol2')"></p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+16.000 Users</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left" v-html="$t('lamoraglamour.titlesol3')"></h2>
          <p class="text-paragraph" v-html="$t('lamoraglamour.textsol3')"></p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right"> +157% Sales</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>