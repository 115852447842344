import { createI18n } from "vue-i18n";
import it from "./languages/it.json";
import en from "./languages/en.json";

function loadLocaleMessages() {
    const locales = [{ it: it }, { en: en }];
    const messages = {};
    locales.forEach((lang) => {
        const key = Object.keys(lang);
        messages[key] = lang[key];
    });
    return messages;
}
export default createI18n({
    locale: "it",
    fallbackLocale: "en",
    legacy: false,
    messages: loadLocaleMessages(),
});