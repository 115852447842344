<template>
  <div class="content">

  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Ffriday",
  components: {

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>