<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="title-section" data-aos="fade-left">DISTINTA<br>BASE</h1>
          <p class="text-paragraph">
            Bye bye sprechi!<br>
            Con il nostro <b>software in cloud</b> avrai finalmente sott'occhio il food cost e le scorte delle materie prime della tua attività.
            Contattaci per installarlo sul tuo sito web dal bottone qui sotto oppure scarica il manuale operativo.
          </p>
          <div class="btn-group">
            <a target="_blank" class="btn btn-outline-dark btn-lg" href="/img/distintabase/manuale-operativo-diba.pdf">Manuale operativo</a>
            <router-link class="btn btn-dark btn-lg" to="/contatti">Contattaci</router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <video width="100%" poster="/img/distintabase/poster.png" class="video" controls ref="videocasasaturno" title='videocasasaturno'>
            <source src="/video/video-diba.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Tieni d’occhio i costi nascosti!</h4>
          <TextScroller :class="'casa1'" :text="'La Distinta Base è un software indispensabile per ogni imprenditore che si rispetti. Infatti, arricchisce le funzionalità del magazzino di Cassa in Cloud, il quale gestisce soltanto i prodotti singoli ma non i prodotti complessi (cioè composti da una ricetta). Con la Distinta Base, chi vorrà tenere sotto controllo le scorte in magazzino potrà creare le ricette di tutti i prodotti compositi (es. pizza, carbonara o tortino al cioccolato) e vedere scalati gli ingredienti con le loro quantità ogni volta che avviene una vendita.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Le funzionalità</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M8.742 2.397c.82-.861 1.977-1.397 3.258-1.397 1.282 0 2.439.536 3.258 1.397.699-.257 1.454-.397 2.242-.397 3.587 0 6.5 2.912 6.5 6.5 0 2.299-1.196 4.321-3 5.476v9.024h-18v-9.024c-1.803-1.155-3-3.177-3-5.476 0-3.588 2.913-6.5 6.5-6.5.788 0 1.543.14 2.242.397zm6.258 19.603h5v-7.505c-.715.307-1.38.47-1.953.525-.274.026-.518-.176-.545-.45-.025-.276.176-.52.451-.545 1.388-.132 5.047-1.399 5.047-5.525 0-3.036-2.465-5.5-5.5-5.5-1.099 0-1.771.29-2.512.563-1.521-1.596-2.402-1.563-2.988-1.563-.595 0-1.474-.026-2.987 1.563-.787-.291-1.422-.563-2.513-.563-3.035 0-5.5 2.464-5.5 5.5 0 4.13 3.663 5.394 5.048 5.525.274.025.476.269.45.545-.026.274-.27.476-.545.45-.573-.055-1.238-.218-1.953-.525v7.505h5v-3.5c0-.311.26-.5.5-.5.239 0 .5.189.5.5v3.5h4v-3.5c0-.311.26-.5.5-.5s.5.189.5.5v3.5z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Creazione ricette</h2>
          <p class="text-paragraph">
            Potrai creare le tue ricette direttamente sul nostro gestionale ed associarci in modo facile gli ingredienti:
            il prodotto ricetta verrà creato anche su Cassa in Cloud in modo automatico.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21.995 6c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v12c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-18.5.5h17v11h-17zm4 2.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75v6.5c0 .414.336.75.75.75s.75-.336.75-.75z" fill-rule="nonzero"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Creazione semilavorati</h2>
          <p class="text-paragraph">
            Il nostro software ha all'interno anche la gestione dei semilavorati: i ristoratori possono creare dei preparati
            composti da ingredienti in modo da essere usati all'interno di altre ricette.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.919 17.377l-4.869-13.377h-2.05c-.266 0-.52-.105-.707-.293-.188-.187-.293-.442-.293-.707 0-.552.447-1 1-1h3.45l5.469 15.025c.841.101 1.59.5 2.139 1.088l11.258-4.097.684 1.879-11.049 4.021c.032.19.049.385.049.584 0 1.932-1.569 3.5-3.5 3.5-1.932 0-3.5-1.568-3.5-3.5 0-1.363.781-2.545 1.919-3.123zm1.581 1.811c.724 0 1.312.588 1.312 1.312 0 .724-.588 1.313-1.312 1.313-.725 0-1.313-.589-1.313-1.313s.588-1.312 1.313-1.312zm13.314-6.512l-11.418 4.156-2.736-7.518 11.418-4.156 2.736 7.518zm-8.71-3.215l-2.88 1.048 1.368 3.759 7.659-2.787-1.368-3.759-2.9 1.055.684 1.879-1.879.685-.684-1.88zm2.672-4.165l-8.458 3.078-1.927-5.296 8.457-3.078 1.928 5.296zm-3.123-2.733l-4.699 1.711.56 1.537 4.698-1.71-.559-1.538z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Monitoraggio materie prime</h2>
          <p class="text-paragraph">
            Con questa soluzione puoi gestire facilmente i prodotti composti (ricette) e le materie prime associate ad essi
            in modo da scaricarle a magazzino quando si esegue la vendita di un prodotto ricetta.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.185 4l2.113-4 1.359.634-1.817 3.366h4.199c0 .922 1.092 1.618 1.961 1.618v1.382h-1l-2 17h-10l-2.02-17h-.98v-1.382c.87 0 2-.697 2-1.618h6.185zm-5.185 3l1.735 15h6.53l1.735-15h-10zm3.75 10.5c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm2.727-2.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-2.977-3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Gestione beverage e cocktail</h2>
          <p class="text-paragraph">
            In modo analogo alla gestione dei semilavorati si possono gestire anche le bevande alla mescita trattando
            la bottiglia come semilavorato e un calice di essa come prodotto ricetta.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 20h-13c-2.481 0-4.5-2.019-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.79c.185-3.447 3.031-6.146 6.48-6.146 3.449 0 6.295 2.699 6.479 6.146l.043.79.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.481-2.019 4.5-4.5 4.5m.979-9.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">Collegamento con Cassa in Cloud</h2>
          <p class="text-paragraph">
            Il nostro software è collegato direttamente dai nostri tecnici alla licenza del cliente tramite le API di
            Cassa in Cloud in modo da fare un'integrazione veloce e sicura.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import TextScroller from "@/components/TextScroller.vue";

export default {
  name: "DistintaBase",
  components: {
    TextScroller
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>