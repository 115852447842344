<template>
  <section class="section-home">
    <video src="/video/trailer.mp4" muted="" autoplay="" loop="" playsinline></video>
    <div class="layer">
      <div class="leading-home">
        <h1 class="mb-3" data-aos="fade-left" data-aos-anchor-placement="center-bottom" v-html="$t('home.lead')"></h1>
        <button class="btn btn-lg btn-outline-light">{{$t("home.button")}}</button>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">{{$t("home.whoweare")}}</h4>
          <TextScroller :class="'home1'" :text="$t('home.textwwa')"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrappersticky">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <BoxParticles :height="700"/>
        </div>
        <div class="col-lg-8">
          <!--<div class="card cardhome" style="background-image: url('img/_IUL2800.jpg'); background-size: cover;">
            <div class="layer"></div>
          </div>-->
          <router-link to="/digital">
            <div class="card cardhome">
              <h1 v-html="$t('home.boxr1')"></h1>
              <!--<h1>Unified commerce<br>and AI platforms<br>since years</h1>-->
              <p>
                Siamo un'azienda digital che offre consulenza software specializzata
              </p>
              <!--<p>
                By leveraging
                collected retailer data, they enhance their AI systems, enabling retailers to provide a consistent and personalized
                shopping experience across physical stores, online shops, and mobile applications.
              </p>-->
              <!--<p>
                Over the past two years, Saturno Consulting has dedicated significant resources to developing advanced unified
                commerce platforms. These enable retailers to manage their inventory, sales, customer data, and
                marketing efforts from a single, cohesive system.
              </p>
              <p>
                We collect a lot of retailer's data that we use to improve our AI systems and retailers
                can offer a consistent and personalized shopping experience across all channels, from physical stores to online
                shops and mobile applications.
              </p>-->
            </div>
          </router-link>
          <router-link to="/retail">
            <div class="card cardhome" style="background-image: url('img/AI.png'); background-size: cover;">
              <h1 v-html="$t('home.boxr2')"></h1>
              <p>
                Creiamo soluzioni per la vendita al dettaglio integrate con le nuove tecnologie di AI.
              </p>
              <!--<h1>Our case history<br>make us who we are</h1>
              <p>
                Below you can see some of the case histories of customers that use our platforms and manage all the digital
                structure for unified commerce aspects with us.
              </p>
              <div class="row">
                <div class="col-lg-6 mt-3">
                  <router-link to="/lamoraglamour">
                    <img class="img-fluid" src="/img/logo-lamora.png">
                  </router-link>
                </div>
                <div class="col-lg-6 mt-3">
                  <img class="img-fluid" src="/img/logo-bkl.png">
                </div>
              </div>-->
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <h4 class="text-grey mb-4">La nostra soluzione per l'offline</h4>
        <div class="col-lg-6">
          <h1 class="title-section mt-4 mb-4" data-aos="fade-left">Customer<br>Welcome</h1>
        </div>
        <div class="col-lg-6">
          <p class="text-paragraph text-white">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
          <button class="btn btn-lg btn-outline-light mt-4 mb-4">{{$t("home.button")}}</button>
        </div>
      </div>
    </div>
  </section>
  <!--<section class="wrapper swiperwrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-5">
          <h4 class="text-grey mb-4">I nostri prodotti</h4>
          <h1 class="title-section" data-aos="fade-right">Strumenti<br>e tecnologie AI<br>per potenziare le aziende</h1>
          <div class="mt-4">
            <button class="btn btn-lg btn-slider swiper-button-prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
              </svg>
            </button>
            <button class="btn btn-lg btn-slider swiper-button-next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <swiper
              :slides-per-view="1"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :space-between="50"
              :effect="'creative'"
              :modules="modules"
              :creativeEffect="{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }"
          >
            <swiper-slide>
              <h4>FFriday</h4>
              <img class="img-fluid img-swiper" src="/img/software/friday-dashboard.jpg">
            </swiper-slide>
            <swiper-slide>
              <h4>AmicoPOS</h4>
              <img class="img-fluid img-swiper" src="/img/software/amicopos.png">
            </swiper-slide>
            <swiper-slide>
              <h4>WooConnector</h4>
              <img class="img-fluid img-swiper" src="/img/software/wc1.png">
            </swiper-slide>
            <swiper-slide>
              <h4>Distinta base</h4>
              <img class="img-fluid img-swiper" src="/img/software/db1.png">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>-->
  <!--<section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">La nostra mission</h4>
          <TextScroller :class="'home2'" :text="'However, for brands, AI often feels distant and confined to expert developers, especially in eCommerce where reliance on off-the-shelf SAAS products can lead to outdated technology and a significant gap between innovation and accessibility. We have closed that gap.'"/>
        </div>
      </div>
    </div>
  </section>-->
  <section class="wrapper bg-white blog">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Parliamo di<br>customer experience</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-4">
          <div class="card bg-black text-white">
            <a href="#">
              <img class="card-img-top" src="/img/ai.jpeg" alt="Card image cap">
            </a>
            <div class="card-body">
              <h5 class="title-blog">Prova titolo di articolo blog</h5>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card bg-black text-white">
            <a href="#">
              <img class="card-img-top" src="/img/ai.jpeg" alt="Card image cap">
            </a>
            <div class="card-body">
              <h5 class="title-blog">Prova titolo di articolo blog</h5>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card bg-black text-white">
            <a href="#">
              <img class="card-img-top" src="/img/ai.jpeg" alt="Card image cap">
            </a>
            <div class="card-body">
              <h5 class="title-blog">Prova titolo di articolo blog</h5>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--<section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left" v-html="$t('home.discover')"></h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.602 3.7c-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 3.321 0 5.97 2.117 5.97 6.167 0 3.555-1.949 6.833-2.383 7.833h-2.115c.392-1.536 2.499-4.366 2.499-7.842 0-5.153-5.867-4.985-7.369-2.458zm13.398 15.3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">{{$t('home.title1')}}</h2>
          <p class="text-paragraph" v-html="$t('home.text1')"></p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.428 6c6.018 0 8.414 10 13.143 10 2.114 0 3.48-1.578 3.48-4s-1.366-4-3.48-4c-1.665 0-2.861 1.069-4.017 2.395l-1.244-1.562c1.499-1.532 3.049-2.833 5.26-2.833 3.198 0 5.43 2.455 5.43 6s-2.232 6-5.43 6c-6.003 0-8.406-10-13.142-10-2.113 0-3.479 1.578-3.479 4s1.365 4 3.479 4c1.664 0 2.86-1.068 4.015-2.392l1.244 1.561c-1.499 1.531-3.05 2.831-5.259 2.831-3.197 0-5.428-2.455-5.428-6s2.231-6 5.428-6z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">{{$t('home.title2')}}</h2>
          <p class="text-paragraph" v-html="$t('home.text2')"></p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <h1 class="title-data" data-aos="fade-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 7h-4v-3c0-2.209-1.791-4-4-4s-4 1.791-4 4v3h-4l-2 17h20l-2-17zm-11-3c0-1.654 1.346-3 3-3s3 1.346 3 3v3h-6v-3zm-4.751 18l1.529-13h2.222v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h6v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h2.222l1.529 13h-15.502z"/></svg>
          </h1>
        </div>
        <div class="col-lg-8">
          <h2 class="title" data-aos="fade-left">{{$t('home.title3')}}</h2>
          <p class="text-paragraph" v-html="$t('home.text3')"></p>
        </div>
      </div>
    </div>
  </section>-->
  <section class="wrapper swiperwrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="mb-4">{{$t('home.tech')}}</h4>
        </div>
        <div class="col-lg-12">
          <swiper
              :slides-per-view="5"
              :space-between="50"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :autoplay="autoplay"
              :effect="'swiper'"
              :loop="true"
              :breakpoints="breakpoints"
              :modules="modules"
          >
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/1-vue.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/2-laravel.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/3-react.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/4-aws.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/5-ovh.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/6-angular.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/2-laravel.jpg">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, EffectCreative } from 'swiper/modules';
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
import 'swiper/css';
import 'swiper/css/effect-creative';

export default {
  /* eslint-disable */
  name: 'Home',
  mounted() {
    //let el = document.getElementById('sticky');
    //let nav = document.getElementById('navbar')
    /*
    window.onscroll = function() {
      if (window.pageYOffset + 130 > el.offsetTop) {
        el.classList.add("position-sticky");
      } else {
        el.classList.remove("position-sticky");
      }
    }
     */
  },
  components: {
    Swiper,
    SwiperSlide,
    TextScroller,
    BoxParticles
  },
  data () {
    return {
      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      },
      breakpoints: {
        '@0.30': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '@0.75': {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        '@1.00': {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        '@1.50': {
          slidesPerView: 6,
          spaceBetween: 50,
        },
      }
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules : [Autoplay, Navigation, EffectCreative]
    };
  },
  methods : {

  }
}
</script>
<style scoped>

</style>