<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-5">
          <h1 class="title-section" data-aos="fade-left">Antonello<br>Dulcamara</h1>
          <p class="text-paragraph">
            Head of Sales
          </p>
          <div class="mt-5">
            <h4 class="subtitle mb-1 fw-bolder">Saturno Consulting Srl</h4>
            <p class="text">
              Omnichannel solutions experts<br><br>
              <b>Indirizzo:</b> Via Cascina 6, 67100 L'Aquila (AQ)<br>
              <b>P.IVA:</b> 02009750668<br><br>
              <b>Telefono:</b> 347 8313815<br><br>
              <b>Sito web:</b> www.saturnoconsulting.com<br>
              <b>E-mail:</b> antonello@saturnoconsulting.com<br><br>
              <b>Codice SDI:</b> M5UXCR1<br>
              <b>PEC:</b> saturnoconsulting@kelipec.it<br><br>
              <a href="/antonellodulcamara.vcf" class="btn btn-dark">Salva contatto</a>
            </p>
            <p>
              <img class="imgqr mt-3" src="/img/qr/qr-antonello.png">
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <BoxParticles :height="800"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Antonello Dulcamara",
  data(){
    return{

    }
  },
  components: {BoxParticles},
  mounted() {

  }
}
</script>

<style scoped>

.imgqr{
  width: 200px;
}

@media screen and (max-width: 992px) {
  .fl-block{
    display: none;
  }
}
</style>