import { createApp } from 'vue';
import i18n from './i18n';
import App from './App.vue'
import store from "@/store";
import router from "@/router";
import './interceptors/axios';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import AOS from 'aos';
import 'aos/dist/aos.css'

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(AOS.init({
        duration: 1000
    }))
    .mount('#app')
